<template>
    <div>
        <b-sidebar backdrop id="add_users" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="formtitle" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{formtitle}}</span>
                </div>
                <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="user.empid"
                            :items="employees"
                            :label="$parent.lang.choose_employee"
                            @change="userfullname()"
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :label="$parent.lang.username + ` *`"
                            v-model="user.username"
                            >{{ user.username }}</v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :label="$parent.lang.full_name + ` *`"
                            v-model="user.full_name"
                            >{{ user.full_name }}</v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            :label="$parent.lang.password + ` *`"
                            v-model="user.password"
                            type="password"
                            >{{ user.password }}</v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="user.group_id"
                            :items="perms"
                            :label="$parent.lang.permissions"
                        ></v-select>
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addUser()' class="ma-2" style="width:100px;">{{ SUBMIT_NAME }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            perms:[
                {
                    text: this.$parent.lang.cashier,
                    value: 3,
                },
                {
                    text: this.$parent.lang.accountant,
                    value: 2,
                },
                {
                    text: this.$parent.lang.administratior,
                    value: 1,
                },
            ],
            permissions:{
                text: this.$parent.lang.cashier,
                value: 3,
            },
            formtitle: this.$parent.lang.add_user,
            SUBMIT_NAME: this.$parent.lang.add,
            employees: [],
            user:{
                id: 0,
                username: '', 
                full_name: '', 
                password: '', 
                group_id: 0, 
                created_by: 0, 
                created_date: '', 
                updated_by: 0, 
                updated_date: '', 
                company_id: 1, 
                branch_id: 1, 
                last_login_date: '', 
                laste_session_update: '', 
                status: 1,
                empid: 0
            }
        }
    },
    methods: {
        userfullname(){
            this.user.full_name = this.employees[this.user.empid];
        },
        getEmp(){
            const post = new FormData();
            post.append("type","getEmpl");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path , post
            )
            .then((res) => {
                // console.log(res);
                this.employees = res.data.results.data;
            });
        },
        resetValues(){
            this.user = {
                id: 0,
                username: '', 
                full_name: '', 
                password: '', 
                group_id: 0, 
                created_by: 0, 
                created_date: '', 
                updated_by: 0, 
                updated_date: '', 
                company_id: 1, 
                branch_id: 1, 
                last_login_date: '', 
                laste_session_update: '', 
                status: 1
            }
        },
        addUser(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            const post = new FormData();
            post.append("type",'addUser');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.user.id);
            post.append("data[username]",this.user.username);
            post.append("data[full_name]",this.user.full_name);
            post.append("data[password]",this.user.password);
            post.append("data[group_id]",this.user.group_id);
            post.append("data[empid]",this.user.empid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    const res = resposnse.data;
                    // console.log(res);
                    this.resetValues();
                    if(res.error.number == 200){
                        this.$snotify.create({
                            title: 'اضافة / تعديل',
                            body: 'تمت العملية بنجاح',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }else{
                        this.$snotify.error({
                            title: 'اضافة / تعديل',
                            body: 'حصل خطأ اثناء الاضافة/التعديل',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }
                }
            )
        }
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
       this.getEmp()
    },
}
</script>